*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
ol, ul,li {
    list-style: none;
  }
a {
    text-decoration: none;
    color: inherit;
  }
.navbar{
        /* background-color: pink; */
        width: 100%;
        max-width: 1920px;
        position:fixed;
        top: 0;
        left:50%;
        transform: translateX(-50%);
        z-index: 2;
       
}
.navbar:hover{
    background-color: rgba(41, 52, 60,0.9) !important;
}
.nav{
    max-width: 1400px;
    
    width: 100%;
    height: 84px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-icon{
    margin-right: 80px;
}
.nav .nav-icon img{
    width:122px;
    height: 32px;
}
.nav-ul{
    /* width: 628px; */
    gap:30px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    position: relative;
    padding: 5px 0px;
    overflow: hidden;
    /* padding-bottom:6px; */
}
.nav-ul .nav-item{
    cursor: pointer;
}
.nav-ul .underline{
    position: absolute;
    background-color: #3498db;
    height: 2px;
    left: -100px;
    top: 28px;
    transform: all 0.3s ease;
    width:100px;
    border-radius: 2px;

}
.nav .nav-left{
    display: flex;
    align-items: center;
}
.nav .nav-right{
    /* margin-left: 186px; */
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    cursor: pointer;
}
.nav .nav-right > div:first-child{
    margin-right: 30px;
}
.bottom{
    height: 70px;
    width: 100%;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_page{
    min-height: 100vh;
    width: 100%;
    max-width:1920px;
    margin: 0 auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}

.b_title{
    text-align: center;
    margin: 50px 0;
    .title{
        font-size: 34px;
        color: #c7c7c7;
        font-weight: 700;
    }
    .subtitle{
        font-size: 24px;
    }
    .f{
        margin-top: 6px;
    }
    .ssubtitle{
        margin-top: 6px;
    }
    /* margin: 0 auto; */
}